import React, { useEffect,useState } from "react";
import { BrowserRouter,useHistory, Route, Switch } from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent";
import liff from '@line/liff';
import UserService from "./services/UserService";
import { getStorage,getSecretAuth } from "src/utils/utils";
import jwt from "jsonwebtoken";
// Pages
const UILogin = React.lazy(() => import("../src/views/UILogin"));
const UIRegister = React.lazy(() => import("../src/views/UIRegister"));
const TheLayout = React.lazy(() => import("./components/TheLayout"));

const App = () => {
  var displayRoute = LoadingComponent;
  const route = (
    <BrowserRouter>
          <React.Suspense fallback={LoadingComponent}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login"
                render={(props) => <UILogin {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register"
                render={(props) => <UIRegister {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
  );

  const queryString = decodeURIComponent(window.location.search).replace("?liff.state=", "");
  const params = new URLSearchParams(queryString);
  var page = params.get('p');
  const rawParams = new URLSearchParams(window.location.search);
  const auth = rawParams.get('auth');
  
  const LoginLine = (line_token,th,ct)=>{
    UserService.memberLoginLine({line_token})
    .then(({ data }) => {
      let dataWraped = {
        firstname: data.firstname,
        lastname: data.lastname,
        user_agent: data.user_agent,
      };
      sessionStorage.setItem("userInfo", JSON.stringify(dataWraped));
      sessionStorage.setItem("MemberId", data._id);
      sessionStorage.setItem("mem_deposit_count", data.deposit_count);
      th();
    })
    .catch((err) => {
      ct();
    });
  };

  

  const redirectPage = ()=>{
    if(!page) page = "";
    window.location.href = "/"+page;
  }

  
  
  if(auth){
    page = rawParams.get('p');
    let secret = getSecretAuth(process.env.REACT_APP_DEV_ENDPOINT);
    jwt.verify(auth, secret, function(err, decoded) {
      if(!err){
        let dateNow = new Date();
        if(decoded.exp>dateNow.getTime()){
          LoginLine(decoded.token,redirectPage,redirectPage);
        }else redirectPage();

      }else redirectPage();
    });
  }
  

  
  
    liff
    .init({liffId: process.env.REACT_APP_LINE_LIFF_ID})
    .then(() => {
      
      // alert(page);
      if (liff.isLoggedIn() && liff.isInClient() && !getStorage("line_token")) {
        let line_token = liff.getContext().userId;
        sessionStorage.setItem("line_token",line_token);
        LoginLine(line_token,redirectPage,redirectPage);
        
      }

    });

    if(getStorage("line_token") || !liff.isInClient()){
      displayRoute = route;
    }
 
    // if(!auth) displayRoute = route;
  
  return displayRoute;
};

export default App;

import $ from "jquery";
import CryptoJS from "crypto-js";
import icon1 from "src/assets/img/g_menu1.png";
import icon2 from "src/assets/img/g_menu2.png";
import iconGame from "src/assets/img/icongame/icongame.png";
import iconSlotGame from "src/assets/img/icongame/iconslotgame.png";
import iconFishGame from "src/assets/img/icongame/iconfishgame.png";
import iconBonusGame from "src/assets/img/icongame/iconbonusgame.png";
import iconJackpot from "src/assets/img/icongame/iconjackpot.png";
import iconAllGame from "src/assets/img/icongame/iconallgame.png";
import iconFreeGame from "src/assets/img/icongame/iconfreegame.png";
import { PROVIDER_TYPE } from "src/context/constant";
import GAME_CONSTANT from "src/context/gameList.json";

const timeTwoDigit = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export const compare = (a, b) => {
  if (a.state_index < b.state_index) {
    return -1;
  }
  if (a.state_index > b.state_index) {
    return 1;
  }
  return 0;
};

export const formatDateTime = (time, type) => {
  let d = new Date(time);
  let h = timeTwoDigit(d.getHours());
  let m = timeTwoDigit(d.getMinutes());
  let s = timeTwoDigit(d.getSeconds());

  if (type === "onlyTime") {
    return `${h}:${m}:${s}`;
  } else if (type) {
    return `${d.toLocaleDateString("th-TH")}`;
  }
  return `${d.toLocaleDateString("th-TH")} - ${h}:${m}:${s}`;
};

export const getStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const Validate = {
  Form: (store) => {
    let verifyList = [];
    $.each(store, (idx, record) => {
      let type = record.nodeName;
      let select = $(record).find(".ant-select-selector")[0];
      let verify = true;

      if (type === "DIV" || type === "SPAN") {
        if (select) {
          if ($(record).find(".ant-select-selection-placeholder")[0]) {
            verify = false;
          }
        } else {
          if ($(record).find("textarea")[0]) {
            if ($(record).find("textarea")[0].innerHTML.trim() === "") {
              select = $(record).find("textarea")[0];
              verify = false;
            }
          } else if (
            $(record).find("input").val().trim() === "" ||
            $(record).find("input").val() === null
          ) {
            select = $(record).find("input");
            verify = false;
          }
        }
      } else {
        if ($(record).val() === null || $(record).val().trim() === "") {
          verify = false;
        }
      }

      if (verify) {
        select
          ? $(select).removeClass("input-validate-error")
          : $(record).removeClass("input-validate-error");
      } else {
        select
          ? $(select).addClass("input-validate-error")
          : $(record).addClass("input-validate-error");
      }
      verifyList.push(verify);
    });
    // console.log("LIST OF DATA ==> ", verifyList);
    return !verifyList.includes(false);
  },
  Input: (target) => {
    $(target).val() === null || $(target).val().trim() === ""
      ? $(target).addClass("input-validate-error")
      : $(target).removeClass("input-validate-error");
  },
  ResetClass: (store) => {
    $.each(store, (idx, record) => {
      $(record)
        .find(".ant-select-selector")
        .removeClass("input-validate-error");
      $(record).removeClass("input-validate-error");
    });
  },
  // AcceptNumber: (value) => {
  //   let regex = /^\d+$/;
  //   return !regex.test(value);
  // },
};

export const ObjArrentriesToObj = (obj) => {
  const arr = [];

  for (const [key, value] of Object.entries(obj)) {
    value.forEach((game) => {
      arr.push(game);
    });
  }

  return arr;
};

export const gameListToGameGroup = (obj) => {
  const game_list = [];

  obj.forEach((el) => {
    const find_game = GAME_CONSTANT.find(
      (game) => game.name.toLowerCase() == el.game_name.toLowerCase()
    );
    if (find_game) {
      el.game_type = find_game.type;
    } else {
      el.game_type = "etc";
    }
    game_list.push(el);
  });

  return game_list;
};

export const groupByType = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getSecretAuth = (key) => {
  let apiURL = new URL(key);
  let apireverse = apiURL.host.toLowerCase().split("").reverse().join("");
  let secret = CryptoJS.MD5(apireverse).toString();
  return secret;
};

export const getTypeDetail = (type) => {
  switch (type.toLowerCase()) {
    case PROVIDER_TYPE.CASINO:
      return { name: "คาสิโน", class: "imgeffect", icon: icon2 };
    case PROVIDER_TYPE.FISHING:
    case PROVIDER_TYPE.FH:
      return { name: "เกม/ยิงปลา", class: "imgeffect", icon: iconFishGame };
    case PROVIDER_TYPE.SLOT:
    case PROVIDER_TYPE.SLOTS:
      return { name: "สล็อต", class: "imgeffect", icon: iconSlotGame };
    case PROVIDER_TYPE.TABLE:
    case PROVIDER_TYPE.TABLE_GAME:
    case PROVIDER_TYPE.EGAME:
      return { name: "เกม", class: "imgeffect", icon: iconGame };
    case PROVIDER_TYPE.ARCARD:
      return { name: "เกม", class: "imgeffect", icon: iconFishGame };
    case PROVIDER_TYPE.ECASINO:
      return { name: "เกม", class: "imgeffect", icon: iconBonusGame };
    case PROVIDER_TYPE.JACKPOT:
      return { name: "แจ๊คพอต", class: "imgeffect", icon: iconJackpot };
    case PROVIDER_TYPE.GAME:
      return { name: "เกม", class: "imgeffect", icon: iconGame };
    case PROVIDER_TYPE.FREE_SPIN:
      return { name: "ฟรีสปิน", class: "imgeffect", icon: iconFreeGame };
    case PROVIDER_TYPE.BONUS_BUY:
      return { name: "โบนัส", class: "imgeffect", icon: iconBonusGame };
    case PROVIDER_TYPE.ALL:
      return { name: "ทั้งหมด", class: "imgeffect", icon: iconAllGame };
    default:
      return { name: "ทั้วไป", class: "imgeffect", icon: icon1 };
  }
};

export const PUBLIC_IMAGES_URL = {
  BANK: `${process.env.REACT_APP_DEV_ENDPOINT}/images/bank`,
  PROMOTION: `${process.env.REACT_APP_DEV_ENDPOINT}/images/promotion`,
  REWARD: `${process.env.REACT_APP_DEV_ENDPOINT}/images/reward-point`,
  BANNER: `${process.env.REACT_APP_DEV_ENDPOINT}/images/banner`,
};

export const GAME_CATEGORY = {
  CASINO: "Casino",
  BINGO: "Bingo",
  ECASINO: "ECasino",
  FISHING: "Fishing",
  SLOT: "Slot",
  NEW_GAME: "NewGame",
};

export const PROVIDER_TYPE = {
  CASINO: "casino",
  FISHING: "fishing",
  FH: "fh",
  SLOT: "slot",
  SLOTS: "slots",
  TABLE: "table",
  TABLE_GAME: "tablegame",
  FREE_SPIN: "freespin",
  BONUS_BUY: "bonusbuy",
  EGAME: "egame",
  ARCARD: "arcade",
  ECASINO: "ecasino",
  JACKPOT: "jackpot",
  GAME: "game",
  ALL: "all",
};

export const REWARD_REQUEST_TYPE = {
  APPROVED: "approved",
  REJECTED: "reject",
  PENDING: "pending",
};

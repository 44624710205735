import axios from "axios";

const ENDPOINT = {
  USER: {
    GET_USER_INFO: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/refresh`,
    LOGIN: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/login`,
    LOGIN_LINE: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/line`,
    REGISTER: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/member-register`,
    CHANGE_PASSWORD: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/change-password`,

    WITHDRAW: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/withdraw`,
    GET_WITHDRAW_HISTORY: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/withdraw-history`,
    GET_DEPOSIT_HISTORY: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/deposit-history`,

    GET_REFUND_HISTORY: ({ memberId }) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/refund-credit-history/${memberId}`,
    GET_FIXED_DEPOSIT_CONFIG: (memberId) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/fixed-deposit/${memberId}`,
    REFUND_CREDIT: (memberId) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/refund-credit-receive/${memberId}`,
    RECIEVE_FIXED_DEPOSIT: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/fixed-deposit-receive`,
  },

  AFFILIATE: {
    GET_AFFILIATE_HISTORY: (memberId) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/affiliate-history/${memberId}`,
    RECEIVE_AFFILIATE_INCOME: `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/affiliate-receive`,
    AFFILIATE_CURRENT_INCOME: (memberId) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/affiliate/${memberId}`,
    GET_AFFILIATE_MEMBER_INVITE: ({ page, limit, memberId }) =>
      `${process.env.REACT_APP_DEV_ENDPOINT}/member-hook/affiliate-list?page=${page}&limit=${limit}&member_id=${memberId}`,
  },
};

const UserService = {
  getUserInfoById: (_id) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.GET_USER_INFO,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data: { member_id: _id },
    });
  },

  memberRegister: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.REGISTER,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  memberLogin: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.LOGIN,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  memberLoginLine: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.LOGIN_LINE,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  userWithdraw: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.WITHDRAW,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  withdrawHistory: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.GET_WITHDRAW_HISTORY,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  DepositHistory: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.GET_DEPOSIT_HISTORY,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data: { member_id: data },
    });
  },

  changePassword: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.CHANGE_PASSWORD,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  getRefundHistory: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.USER.GET_REFUND_HISTORY(config),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },

  getFixedDepositConfig: (id) => {
    return axios({
      method: "GET",
      url: ENDPOINT.USER.GET_FIXED_DEPOSIT_CONFIG(id),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },

  refundCreditByMemberId: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.USER.REFUND_CREDIT(memberId),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },

  recieveFixedBonus: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.RECIEVE_FIXED_DEPOSIT,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  // ===== AFFILIATE =======
  getAffiliateReceiveHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.AFFILIATE.GET_AFFILIATE_HISTORY(memberId),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },

  affiliateReceive: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.AFFILIATE.RECEIVE_AFFILIATE_INCOME,
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
      data,
    });
  },

  getAffiliateCurrentIncome: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.AFFILIATE.AFFILIATE_CURRENT_INCOME(memberId),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },

  getAffiliateMemberInvite: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.AFFILIATE.GET_AFFILIATE_MEMBER_INVITE(config),
      headers: { Authorization: `Bearer 611247b1d343c96ded2f67d0` },
    });
  },
};

export default UserService;

import { Spin } from "antd";

const LoadingComponent = (
  <div
    style={{
      height: "75vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      filter:"grayscale(100%)"
    }}
  >
    <Spin size="large" />
  </div>
);

export default LoadingComponent;
